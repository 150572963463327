<template>
  <div class="voip">
    <div
      class="single-calling-source left"
      :title="RInfo.livePeerId"
      @click="reVoip">
      <p
        class="voip-source-status"
        :class="{'voipIng':voipIng}"></p>
      <p class="call-again greenBg hide">
        <i class="iconfont call-again-icon"></i>
      </p>
      <p class="hide">{{RInfo}}</p>

      <p
        class="voipNameExceStyle"
        :title="RInfo.livePeerName">
        {{RInfo.livePeerName}}
      </p>
    </div>
    <audio
      ref="audioObject"
      autoplay
      class="audioObject"
      :id="RInfo.peerId"></audio>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import { multiviewAnalyze } from '@/assets/js/googleAnalyze/multiview.js'

export default {
  props: {
    currentRInfo: Object // 当前可以通话的R信息
  },
  data () {
    return {
      RInfo: this.currentRInfo, // 当前R的所有信息
      voipIng: false// 当前是否通话成功
    }
  },
  created () {
    this.RInfo = this.currentRInfo
    const voip = sessionStorage.getItem('voip')
    this.startVoip(voip)
    Bus.$on('voipCloseAll', val => {
      this.voipIng = false
      if (this.RInfo && this.RInfo.livePeerId) {
        this.Webrtc.stopCall('0x' + this.RInfo.livePeerId.toUpperCase())
      }
    })
  },
  computed: {
    ...mapState('common', {
      State: state => state,
      Webrtc: state => state.webrtc
    }),
    VoipStatus () { // 处理voip当前结束通话后 其余voip通话异常的问题
      this.voipIng = false
      console.log(this.RInfo.peerId)
      this.$store.state.common.mvVoipStream.map(item => {
        if (item.peerId == this.RInfo.peerId) {
          this.voipIng = true
          if (item.stream) {
            this.$nextTick(() => {
              this.$refs.audioObject.srcObject = item.stream
            })
          }
        }
      })
      return this.$store.state.common.mvVoipStream
    }
  },
  watch: {
    VoipStatus (newVal, oldVal) {
    }
  },
  methods: {
    startVoip (voip) { // 开始通话
      if (this.RInfo.peerId) {
        let arr = []
        arr.push('0x' + this.RInfo.livePeerId.toUpperCase())
        const that = this
        const voipObj = JSON.parse(voip)
        const _voipObj = { ...voipObj, type: 'audio' }
        this.Webrtc.startCall(arr, _voipObj, (msg) => {
          that.voipIng = false
          if (msg.type == 'noLogin') {
            setTimeout(() => {
              that.startVoip()
            }, 2000)
          } else if (msg.type == 'success') {
            const msgMediaStram = msg.msg.mediaStream
            const thisStream = {
              peerId: that.RInfo.peerId,
              stream: msgMediaStram,
              status: true
            }
            const voipStream = that.State.mvVoipStream
            let isHave = false// vuex内是否有当前R对应的数据
            voipStream.map((item, index) => {
              if (item.peerId == that.RInfo.peerId) {
                isHave = true
                item.stream = msgMediaStram
              }
            })
            if (!isHave) { // 说明vuex内没有此数据对应的voip信息
              voipStream.push(thisStream)
            }
            that.$store.state.common.mvVoipStream = voipStream
            this.$nextTick(() => {
              if (this.$refs.audioObject && this.$refs.audioObject.srcObject) {
                this.$refs.audioObject.srcObject = msgMediaStram
              }
            })
            that.voipIng = true
          } else if (msg.type == 'noHaveMediaDevice') {
            that.$message({
              type: 'warning',
              message: that.$t('lang.getLocalMediaFail')
            })
            return false
          } else if (msg.type === 'disconnected' || msg.type === 'failed') {
            that.voipIng = false
            const voipStream = that.State.mvVoipStream
            if (that.State.mvVoipStream && that.State.mvVoipStream.length > 0) { // 防止断网时,再添加另外的设备 导致computed中voipStatus中让状态置为红色的问题=> 不让 this.voipIng = true;
              voipStream.map((itm, idx) => {
                if (itm.peerId == that.RInfo.peerId) {
                  itm.status = false
                  voipStream.splice(idx, 1)
                }
              })
              that.State.mvVoipStream = voipStream
            }
          }
        })
      }
    },
    reVoip () {
      this.Webrtc.stopCall('0x' + this.RInfo.livePeerId.toUpperCase())
      this.startVoip()
      multiviewAnalyze('multiviewVoipRefresh', this.$route.path)
    }
  },
  beforeDestroy () {
    console.log('voip destroy...' + this.RInfo.peerId)
  }
}
</script>
<style lang="less" scoped>
.voip {
  width: 72px;
  height: 54px;
  float: left;
  padding: 0 8px;
  margin: 0 2px;
  overflow: hidden;
}

.single-calling-source {
  padding-top: 6px;
  cursor: pointer;

  .voip-source-status {
    background: green;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-left: 40%;
    margin-bottom: 6px;
  }

  .voipIng {
    background: red;
  }

  .voipNameExceStyle {
    max-width: 72px;
    display: block;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
  }
}

.audioObject {
  width: 300px;
  height: 100px;
  display: none !important;
  position: absolute;
  top: -20px;
  left: 4px;
  z-index: 0;
}
</style>
