<template>
  <div class="mv-receiverlist">
    <div
      class="borderL"
      :class="{borderClass:!splitItemInfo.isVideoDom}">
      <div
        class="bg-img"
        v-if="socketData.status != 2"
        :class="{'onlineBg':socketData.status == 1,'offlineBg':socketData.status == 0}"></div>
      <div
        class="closeIconBtn hide"
        @click="deleteSelectR"
        v-if="splitItemInfo.isVideoDom">
        <i class="iconfont closeIconMv redColor">&#xe702;</i>
      </div>
      <div
        class="control-status-module"
        v-if="splitItemInfo.isVideoDom&&showInfoOpen">
        <div
          class="left"
          :class="{'allWid':!socketData || socketData.status != 2}">
          <div class="hide">{{splitItemInfo}}</div>
          <span
            class="fontStyle"
            :class="`split${currentSplitIndex}ExceedStyle`"
            :title="splitItemInfo.bookmarkName">{{splitItemInfo.bookmarkName ? splitItemInfo.bookmarkName : ''}}</span>
          <span
            v-if="splitItemInfo.bookmarkId || socketData.bookmarkId"
            class="disLeft"> > </span>
          <span
            class="fontStyle"
            :class="[{'receiverOlineLive' :socketData.status == 2 || socketData.status == 1,
                      'receiverOffline' :socketData.status == 0},`split${currentSplitIndex}ExceedStyle`]"
            :title="splitItemInfo.peerId">
            {{splitItemInfo.name ? splitItemInfo.name : ''}}{{socketData.name&&!splitItemInfo.name ? socketData.name : ''}}
          </span>
          <span
            v-if="(splitItemInfo.livePeerName || socketData.name)&&socketData.status == 2"
            class="fontStyle disLeft"> : </span>
          <span
            :class="[{redColor:splitItemInfo.livePeerName || socketData.livePeerName},`split${currentSplitIndex}ExceedStyle`]"
            class="fontStyle"
            v-if="socketData.status == 2"
            :title="splitItemInfo.livePeerName ? splitItemInfo.livePeerName : ''"> {{splitItemInfo.livePeerName ? splitItemInfo.livePeerName : ''}}{{socketData.livePeerName&&!splitItemInfo.livePeerName ? socketData.livePeerName : ''}}</span>
        </div>
        <div
          class="right"
          v-if="socketData.status == 2">
          <i
            class="iconfont"
            @click="refresh">&#xecb8;</i>
          <i
            class="iconfont redColor"
            v-if="!audioShow && isHaveVideo"
            @click="audioSet(false)">&#xe615;</i>
          <i
            class="iconfont audioOpen"
            v-if="audioShow && isHaveVideo"
            @click="audioSet(true)">&#xe613;</i>
          <i
            class="iconfont"
            @click="fullScreen($event)"
            v-if="isHaveVideo">&#xe658;</i>
        </div>
      </div>
      <div
        class="rtcVideoBox"
        v-if="socketData.status == 2"
        :title="splitItemInfo.peerId">
        <div class="rtcVideo">
          <video-module
            ref="changeVoiceMv"
            :needShowMuteIcon="false"
            :clearSelectR="true"
            height="100%"
            width='100%'
            :useOldR="false"
            deviceType="R"
            :source="socketData"
            :rid="splitItemInfo.peerId"
            :showControls="showInfoOpen"></video-module>
        </div>
      </div>

      <div
        class="mataData "
        v-if="socketData.status  == 2 && metaDataC != '' && metaDataC != null && metaDataC != 'null' &&showInfoOpen">
        <el-popover
          placement="bottom"
          :width="getWidth"
          trigger="hover"
          :visible-arrow="false">
          <div v-html="metaDataC"></div>
          <span
            slot="reference"
            class="mataDataCursor">{{metaDataC}}</span>
        </el-popover>
      </div>
      <div
        class="errorMsg"
        v-if="splitItemInfo.isVideoDom && !rvfOn && socketData.status != 0">
        {{$t('lang.setVideoFeedbackTrueViewVideo')}}
      </div>
    </div>
  </div>
</template>
<script>
import videoModule from '@/components/VideoC'
import Bus from '@/assets/js/vueBus.js'
import { mapState } from 'vuex'
import { multiviewAnalyze } from '@/assets/js/googleAnalyze/multiview.js'

export default {
  props: {
    splitItemInfo: { // 该分屏信息
      type: Object,
      default: () => {
        return {}
      }
    },
    splitSocketInfo: { // 当前websocket中R信息
      type: Object,
      default: () => {
        return {}
      }
    },
    splitIndex: Number,
    showInfoRe: Boolean, // 控制条显示或隐藏的控制
    isFull: Boolean, // 是否点击了隐藏头部的按钮
    mataData: Array
  },
  components: {
    videoModule
  },
  data () {
    return {
      receiverInfo: this.videoInfo,
      audioShow: false, // 声音控制
      hasVideo: false, // 是否有视频
      socketData: this.splitItemInfo, // websocket中的数据
      isMoreLive: false, // 是否显示多个同时在live的错误提示
      showInfoOpen: this.showInfoRe ? this.showInfoRe : false, // show info控制的control module
      currentSplitIndex: this.splitIndex, // 当前分屏信息
      rvfOn: '', // 当前分屏信息
      currentIsFull: this.isFull,
      versionJuge: '',
      isHaveVideo: false, // 是否渲染出来视频
      metaDataC: '',
      historyStatus: {}
    }
  },
  created () {
    if (this.splitItemInfo.version) {
      this.rvfOn = this.splitItemInfo.version.slice(0, 3) >= 6.5 ? true : this.splitItemInfo.rvfPackOn
      if (this.splitItemInfo.version.slice(0, 3) == 6.1) {
        this.rvfOn = true
      }
    }
    Bus.$on('controlModuleChange', (val) => { // 监听showInfo信息
      this.$nextTick(() => {
        if (!val) { // control Module是打开的状态
          this.showInfoOpen = true
          if (this.$refs.changeVoiceMv && this.$refs.changeVoiceMv.returnVideoObj()) {
            const videoObj = this.$refs.changeVoiceMv.returnVideoObj()
            if (videoObj && videoObj.obj) {
              if (videoObj.obj.muted) { // 声音来回切换 保证声音按钮状态一致
                this.audioShow = false
              } else {
                this.audioShow = true
              }
            }
          }
        } else {
          this.showInfoOpen = false
          if (this.$refs.changeVoiceMv && this.$refs.changeVoiceMv.returnVideoObj()) {
            const videoObj = this.$refs.changeVoiceMv.returnVideoObj()
            if (videoObj && videoObj.obj) {
              if (this.audioShow) { // 声音来回切换 保证声音按钮状态一致
                videoObj.obj.muted = false
              } else {
                videoObj.obj.muted = true
              }
            }
          }
        }
      })
    })
    if (this.showInfoOpen) { // 为了向videoC发送controls的消息
      Bus.$emit('controlModuleChangeVideoC', false)
    } else {
      Bus.$emit('controlModuleChangeVideoC', true)
    }
    const that = this
    this.$nextTick(() => {
      this.handleResize()
    })
    window.addEventListener('resize', () => { // 监听页面全屏变化 做相应处理
      if (!that.isFullScreen() && that.currentIsFull) {
        $('#pro_nav_icon_bg').hide()
      }
      if (that.$refs.changeVoiceMv && that.$refs.changeVoiceMv.returnVideoObj()) {
        const videoObj = that.$refs.changeVoiceMv.returnVideoObj()
        if (videoObj.obj.muted) { // 声音来回切换 保证声音按钮状态一致
          that.audioShow = false
        } else {
          that.audioShow = true
        }
      }
      that.handleResize()
    })
  },
  computed: {
    ...mapState('common', {
      States: state => state
    }),
    getWidth () {
      if (this.currentSplitIndex == 4) {
        return 880
      } else if (this.currentSplitIndex == 6) {
        return 580
      } else if (this.currentSplitIndex == 9) {
        return 580
      } else if (this.currentSplitIndex == 16) {
        return 410
      } else if (this.currentSplitIndex == 25) {
        return 340
      } else {
        return 0
      }
    }
  },
  methods: {
    handleResize () {
      const splitBorder = $('.left.splitBorder').width()
      $('.left.splitBorder').css('height', splitBorder * 212 / 363)
    },
    deleteSelectR () { // 删除此分屏
      const param = {
        pn: 'mv',
        type: 'removeReceiver',
        remark: 'receiver'
      }
      let content = {}
      content.peerId = this.splitItemInfo.peerId
      param.content = content
      const emitIfo = {
        operate: 'removeReceiver',
        operatePeerId: param.content.peerId
      }
      let deleteInfo = {
        operate: 'addReceiver',
        operatePeerId: param.content.peerId
      }
      // 向Index.vue通知,删除了一个数据,重置r列表(现在不在接口内做 因为用户操作正确才是最重要的)
      Bus.$emit('splitChangeEmit', emitIfo)
      Bus.$emit('receiverDeleteAndAdd', deleteInfo)// 向bookmark通知,删除了一个数据,将bookMark的选中数组中删除
      multiviewAnalyze('multiviewRemoveReceiver', this.$route.path)
      this.axios.get('/ccp/tvucc-user/userDevice/saveUserBehavior', { params: param }).then(res => {
        if (res.data.errorCode == '0x0') {}
      }).catch(() => {})
    },
    audioSet (val) { // 设置声音
      this.audioShow = !this.audioShow
      this.$refs.changeVoiceMv.changeVideoVoice(val)
      let event = ''
      if (this.audioShow) {
        event = 'Open'
      } else {
        event = 'Close'
      }
      multiviewAnalyze(`multiviewVideoAudio${event}`, this.$route.path)
    },
    refresh () { // 刷新视频
      const videoObj = this.$refs.changeVoiceMv.returnVideoObj()
      if (videoObj.this.webrtcPlayer) {
        videoObj.this.Webrtc.stopCall(videoObj.this.webrtcPlayer)
        videoObj.this.initWebrtc = true
      }
      multiviewAnalyze('multiviewVideoRefresh', this.$route.path)
    },
    fullScreen (event) { // 全屏 没有在live并且没有video标签的时候不能全屏
      const videoObj = this.$refs.changeVoiceMv.returnVideoObj() // 获取当前video对象
      if (videoObj.obj && videoObj.obj.parentNode.style.display != 'none') {
        // 处理没有视频 但是状态为2的情况
        this.launchFullscreen(videoObj.obj)
      }
    },
    launchFullscreen (element) { // 全屏处理
      if (!element) return
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    },
    isFullScreen () { // 返回是否全屏
      return !!(
        document.fullscreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen ||
        document.msFullScreen
      )
    }

  },
  watch: {
    splitSocketInfo (val, oldVal) {
      const peerId = val && val.peerId && val.peerId.toLowerCase()
      if (this.historyStatus && this.historyStatus[peerId]) {
        this.historyStatus[peerId].push(val.status)
      } else {
        this.$set(this.historyStatus, peerId, [val.status])
      }
      if (this.historyStatus[peerId].length > 4) {
        this.historyStatus[peerId].shift()
      }
      if ((this.historyStatus[peerId][0] == 2 || this.historyStatus[peerId][1] == 2 || this.historyStatus[peerId][2] == 2) && val.status == 1) {
        val.status = '2'
      }
      this.socketData = JSON.parse(JSON.stringify(val))
      this.States.deviceListCurrentRInfoData.map((v, i) => {
        if (v.rid == this.splitItemInfo.peerId) {
          if (v.data) {
            const currentRInfo = JSON.parse(v.data)
            if (this.splitItemInfo.version) {
              this.rvfOn = this.splitItemInfo.version.slice(0, 3) >= 6.5 ? true : currentRInfo.RVFPackOn
              if (this.splitItemInfo.version.slice(0, 3) == 6.1) {
                if (currentRInfo.WebRVideoSolution === 300) {
                  this.rvfOn = true
                } else {
                  this.rvfOn = currentRInfo.RVFPackOn
                }
              }
            }
          }
        }
      })

      this.$nextTick(() => {
        if (this.$refs.changeVoiceMv && this.$refs.changeVoiceMv.returnVideoObj) {
          const videoObj = this.$refs.changeVoiceMv.returnVideoObj() // 获取当前video对象
          if (videoObj.obj && videoObj.obj.parentNode && videoObj.obj.parentNode.style.display != 'none') {
            this.isHaveVideo = true
          } else {
            this.isHaveVideo = false
          }
        }
      })
    },
    splitIndex (val) { // 分屏信息监听
      this.currentSplitIndex = val
      this.handleResize()
    },
    isFull (val) {
      this.currentIsFull = val
    },
    mataData (val) {
      if (this.socketData.status == 2) {
        val.map(item => {
          if (this.socketData.livePeerId == item.peerId) {
            this.metaDataC = item.metaData
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.mv-receiverlist {
  width: 100%;
  height: 100%;
  position: relative;

  .borderL {
    width: 100%;
    height: 100%;
  }

  .errorMsg {
    position: absolute;
    top: 50%;
    font-size: 18px;
    font-weight: bolder;
    color: red;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 0 3%;
  }

  ::v-deep .mataData {
    position: absolute;
    bottom: 10%;
    font-size: 18px;
    font-weight: bolder;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 0 3%;
    z-index: 10;
    .el-popover__reference {
      width: 100%;
      display: inline-block;
      font-size: 0.16rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .mataDataCursor {
      cursor: pointer;
    }
  }
  .errorMsgMoreLive {
    position: absolute;
    top: 24%;
    font-size: 18px;
    font-weight: bolder;
    color: #ff9000;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    padding: 0 3%;
  }

  .borderClass {
    width: 100%;
    height: 100%;
    /*background: #333;*/
    box-sizing: border-box;
    border: 1px dashed #444;
  }

  .closeIconBtn {
    position: absolute;
    right: 6px;
    top: 0;
    cursor: pointer;
    z-index: 1;

    .closeIconMv {
      font-size: 0.24rem;
      z-index: 1;
    }
  }

  .bg-img {
    width: 100%;
    height: 100%;
    /*opacity: 0.6;*/
    position: absolute;
    left: 0;
    right: 0;
  }

  .onlineBg {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/logo.png);
    background-size: 15%;
    background-repeat: no-repeat;
    background-color: #2f3330;
    background-position: 50% 40%;
    /*opacity: 0.6;*/
    position: absolute;
    left: 0;
    right: 0;
  }

  .offlineBg {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/MVOffline.png);
    background-size: 15%;
    background-repeat: no-repeat;
    background-color: #2f3330;
    background-position: 50% 45%;
    /*opacity: 0.6;*/
    position: absolute;
    left: 0;
    right: 0;
  }

  .control-status-module {
    width: 100%;
    height: 38px;
    line-height: 36px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid #666;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    .allWid {
      width: 100%;
      .fontStyle {
        display: inline-block;
        width: 45%;
        text-align: left;
        max-width: fit-content;
      }
    }
    .disLeft {
      display: inline-block;
      float: left;
      padding: 0 2px;
    }

    .iconfont {
      font-size: 0.18rem;
      padding: 0 0.04rem;
    }

    .receiverOlineLive {
      color: #33ab4f;
    }

    .receiverOffline {
      color: #999;
    }

    .fontStyle {
      font-weight: bold;
    }

    span {
      cursor: pointer;
    }
  }

  .rtcVideoBox {
    width: 100%;
    height: 100%;

    .rtcVideo {
      width: 100%;
      height: 100%;
      position: relative;

      .url {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: orange;
        width: 90%;
      }

      .receiverInfo {
        position: absolute;
        top: 16px;
        z-index: 5;
        width: 100%;

        .text {
          margin-left: 16px;
          width: 52px;
          height: 24px;
          background: rgba(255, 51, 51, 1);
          border-radius: 2px;
          opacity: 0.76;
          text-align: center;
          line-height: 24px;
        }

        .info {
          font-size: 14px;
          font-weight: bold;
          margin-right: 12px;
        }
      }
    }

    .operationBox {
      padding: 10px 8px;
      background: #08130a;

      .dots {
        background: #33aa50;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        display: inline-block;
        float: left;
        margin-top: 6px;
        margin-right: 5px;
      }

      .hideDot {
        visibility: hidden;
      }

      .TSyncStatus {
        margin-top: 10px;
      }

      .operationBox-top {
        height: 18px;

        .options {
          font-size: 16px;

          .fullScreenIcon:before {
            content: '\e6a3';
            margin-right: 12px;
          }

          .mutedIcon:before {
            content: '\e615';
            margin-right: 12px;
            color: #ff3333;
          }

          .infoIcon:before {
            content: '\e6a0';
          }

          .fullScreenIcon.disabled {
            cursor: not-allowed;
            color: gray;
          }

          .noMutedIcon:before {
            content: '\e613';
            margin-right: 12px;
            color: #fff;
          }
        }
      }

      .operationBox-body {
        height: 32px;
        margin-top: 5px;

        .selectBox {
          width: 185px;
        }
      }
    }
  }
}

.mv-receiverlist:hover .closeIconBtn {
  display: block;
  z-index: 99;
}

.redColor {
  color: red;
}

.split25ExceedStyle {
  max-width: 78px;
  display: inline-block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.split16ExceedStyle {
  max-width: 110px;
  display: inline-block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.split9ExceedStyle {
  max-width: 148px;
  display: inline-block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.split6ExceedStyle {
  max-width: 153px;
  display: inline-block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.split4ExceedStyle {
  max-width: 240px;
  display: inline-block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}
</style>
