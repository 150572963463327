<template>
  <el-cascader
    v-model="checkedDevice"
    :options="bookmarkList"
    :placeholder="$t('lang.Bookmark')"
    :props="{ expandTrigger: 'hover', multiple: true, checkStrictly: true, label: 'name', children: 'bookmarkList' }"
    collapse-tags
    @visible-change="visibleChange"
    popper-class="bookmarkList-cascader bookmark-multil-view">
    <template slot-scope="{ data }">
      <div
        v-if="data.peerId"
        class="ReceiverData"
        @click="handleClick(data)">
        <span
          class="dataSpan"
          :class="{offline:data.status == 0, live:data.status == 2}"
          :title="data.name">
          {{data.name ? data.name : ''}}
        </span>
        <i
          class='selectBox f_left'
          :class="{'active': bookmarkChecked.includes(data.peerId)}"
          :title="data.peerId"></i>
      </div>
      <span v-else>{{ data.name }}</span>
    </template>
  </el-cascader>
</template>

<script>
import Bus from '@/assets/js/vueBus.js'
export default {
  props: {
    bookmarkChecked: Array,
    currentSplitIndex: Number // 当前分屏最大数量
  },
  data () {
    return {
      bookmarkList: [],
      checkedDevice: [],
      active: false,
      selfBookmark: [],
      parentBookmark: [],
      unClassified: []
    }
  },
  computed: {
    accountType () {
      return this.$store.state.common.baseUserInfo.role
    },
    parentName () {
      return this.$store.state.common.baseUserInfo.parentName || ''
    }
  },
  created () {
    document.addEventListener('click', this.outClick, false)
  },
  methods: {
    outClick (event) {
      if (event.target.className != 'el-input__inner' && document.getElementsByClassName('bookmark-multil-view')[0]) {
        document.getElementsByClassName('bookmark-multil-view')[0].style.display = 'none'
      }
    },
    handleClick (data) {
      let addFlag = true
      if (this.bookmarkChecked.includes(data.peerId)) {
        addFlag = false
        const index = this.bookmarkChecked.findIndex(v => v == data.peerId)
        this.bookmarkChecked.splice(index, 1)
      }
      this.handleView(data, addFlag)
    },
    handleView (receiver, isAdd) {
      const sendMsg = {
        pn: 'mv',
        type: isAdd ? 'addReceiver' : 'removeReceiver',
        remark: 'receiver'
      }
      if (isAdd) {
        if (this.bookmarkChecked.includes(receiver.peerId)) return
        const rVersion = (receiver.version.slice(0, 3) - 0) * 10
        if (rVersion < 56 || rVersion == '') {
          this.$message({
            message: this.$t('lang.rVerionNeedV56Above'),
            type: 'warning'
          })
          return false
        }
        if (this.bookmarkChecked.length + 1 > this.currentSplitIndex) { // 超过了当前最大分屏
          const langEn = `Nope. Choose one more view mode to monitor more than${this.currentSplitIndex}videos.`
          const langZh = `请选择一个视图模式来监视超过${this.currentSplitIndex}个视频.`
          const langRu = `Nope. Choose one more view mode to monitor more than${this.currentSplitIndex}videos.`
          let lang = ''
          if (this.$i18n.locale.includes('zh')) {
            lang = langZh
          } else if (this.$i18n.locale.includes('ru')) {
            lang = langRu
          } else {
            lang = langEn
          }
          this.$message({
            type: 'warning',
            message: lang
          })
          return false
        }
        this.bookmarkChecked.push(receiver.peerId)
      }
      sendMsg.content = {
        peerId: receiver.peerId,
        bookmarkId: receiver.bookmarkId
      }
      const emitIfo = {
        operate: sendMsg.type,
        operatePeerId: sendMsg.content.peerId
      }
      if (isAdd) {
        emitIfo.rInterFaceData = receiver
        emitIfo.bookmarkId = receiver.bookmarkId
        emitIfo.bookmarkName = receiver.bookmarkName
      }
      // 现在不在接口内写添加的逻辑 =>因为添加成功才是最主要的 保存到用户行为是次要的
      Bus.$emit('splitChangeEmit', emitIfo)
      this.axios.get('/ccp/tvucc-user/userDevice/saveUserBehavior', { params: sendMsg }).then(res => {
        if (res.data.errorCode == '0x0') {}
      }).catch(() => {})
    },
    visibleChange (status) {
      if (status) this.getAllListData()
    },
    getAllListData () {
      const param = {
        bookmarkId: '',
        activeNum: ''
      }
      this.axios.get('/ccp/tvucc-user/userDevice/getAllChildBookmarkTreeWithDevice', { params: param }).then(res => {
        if (res.data.errorCode == '0x0') {
          const result = res.data.result
          this.selfBookmark = JSON.parse(result.bookmark)
          this.parentBookmark = JSON.parse(result.parentBookmark)
          this.unClassified = JSON.parse(result.receiver)
          this.initFormatDataList(this.selfBookmark)
          this.initFormatDataList(this.unClassified)
          this.selfBookmark.push({
            name: this.$t('lang.unClassified'),
            id: 'unClassified',
            bookmarkList: this.unClassified.length > 0 ? this.unClassified : null
          })
          if (this.accountType == '4' && this.parentBookmark && this.parentBookmark.length > 0) {
            this.initFormatDataList(this.parentBookmark)
            this.hasParent = true
            this.bookmarkList = [{
              id: '-1',
              name: this.$t('lang.myBookmark'),
              bookmarkList: this.selfBookmark
            }, {
              id: '-2',
              name: this.parentName == '' ? this.$t('lang.parentBookmark') : `${this.parentName} ${this.$t('lang.otherBookmark')}`,
              bookmarkList: this.parentBookmark
            }]
          } else {
            this.hasParent = false
            this.bookmarkList = this.selfBookmark
          }
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('lang.i18n_serverErr')
          })
          return false
        }
      }).catch(() => { // 处理接口失败 而导致添加不上 但是bookmark checkValue已经选中的问题.
        this.$message({
          type: 'warning',
          message: this.$t('lang.ParamError')
        })
        // 服务错误
        return false
      })
    },
    initFormatDataList (data) {
      data.forEach(item => {
        if (item.receiverList && item.receiverList.length > 0) {
          let hasChildren = false
          item.receiverList.forEach(device => {
            device.bookmarkList = null
            if (item.bookmarkList) {
              hasChildren = true
              item.bookmarkList.push(device)
            } else {
              item.bookmarkList = []
              item.bookmarkList.push(device)
            }
            device.bookmarkId = item.id
            device.bookmarkName = item.name
          })
          if (hasChildren) this.initFormatDataList(item.bookmarkList)
        }
      })
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.outClick, false)
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-cascader {
  line-height: 32px;
  .el-input .el-input__inner:hover, .el-input .el-input__inner:focus {
    border: 1px solid #444;
  }
  .el-input .el-input__inner:focus,
  .el-input.is-focus .el-input__inner {
    border: 1px solid #444;
    border-color: #444!important;
  }
  &:not(.is-disabled):hover .el-input__inner{
    border-color: #444!important;
  }
}
::v-deep .el-cascader__tags {
  display: none;
}
// .el-cascader-node__postfix
.bookmarkList-cascader {
  position: relative;
  height: 100%;
  ::v-deep .el-cascader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .el-input__icon {
      display: none;
    }
    .el-input__inner {
      background: transparent;
      border: none;
      height: 32px !important;
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::after {
        border-color: #33ab4f !important;
        height: 7px;
        left: 5px;
        top: 1px;
        border-width: 1px;
      }
    }
  }
}

.ReceiverData {
  width: 100%;
  height: 34px;

  .offline {
    color: #999;
  }

  .live {
    color: red;
  }

  .selectBox {
    background-image: url(../../assets/img/v3_selectedIcon.png);
    background-repeat: no-repeat;
    display: inline-block;
    float: right;
    padding-left: 24px;
    font-size: 0.16rem;
    background-position: 6px -42px;
    height: 24px;
    margin-top: 2px;
    // margin-right: 10px;
    cursor: pointer;
  }

  i.active {
    background-position: 6px -15px;
  }

  .dataSpan {
    width: 130px;
    display: inline-block;
    /*float: left;*/
    // padding-left: 10px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
  }
}
</style>
