<template>
  <el-dialog
    :title="$t('lang.AddToCall')"
    :visible.sync="selectDeviceVal"
    width="620px"
    :center="true"
    :close-on-click-modal="false"
    value-key="peerId"
    class="add-source-dialog"
    @close="cancel">
    <div class="content">
      <el-checkbox-group
        v-model="checkList"
        value-key="peerId">
        <el-checkbox
          :label="item.peerId"
          v-for="item in canVoipDataAll"
          :key="item.peerId">
          <div
            v-if="item.bookmarkId"
            class="ecllips left"
            :title="item.bookmarkName">{{item.bookmarkName}}</div>
          <div
            v-if="item.bookmarkId"
            class=" left"> > </div>
          <div
            class="ecllips left"
            :title="item.name">{{item.name}}</div>
          <div class=" left"> : </div>
          <div
            v-if="item.livePeerName"
            class="ecllips left"
            :title="item.livePeerName">{{item.livePeerName ? item.livePeerName : ""}}</div>
          <div
            v-else
            class="ecllips left"
            :title="allsocket[item.peerId] && allsocket[item.peerId].livePeerName? allsocket[item.peerId].livePeerName : ''">{{allsocket[item.peerId] && allsocket[item.peerId].status == 2? allsocket[item.peerId].livePeerName : ''}}</div>
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <span
      slot="footer"
      class="dialog-footer">
      <!--保存，取消按钮-->
      <el-button
        type="primary"
        class="save"
        @click="startCall"
        v-if="isStart">{{$t('lang.startToCall')}}</el-button>
      <el-button
        type="primary"
        class="save"
        @click="editCall"
        v-if="!isStart">{{$t('lang.continueCall')}}</el-button>
      <el-button
        @click="cancel"
        class="cancel">{{$t('lang.cancel')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
import { multiviewAnalyze } from '@/assets/js/googleAnalyze/multiview.js'

export default {
  props: {
    selectDevice: Boolean, // 是否显示
    canVoipData: Array, // 全部可以进行Voip的对象
    currentVoipData: Array, // 当前正在通话的数据
    allSocketData: Array
  },
  data () {
    return {
      checkList: [], // 现在选中的设备
      selectDeviceVal: this.selectDevice, // 控制显示
      canVoipDataAll: this.canVoipData, // 当前可以选择进行voip的设备信息
      currentVoipDataOld: this.currentVoipData,
      isStart: true, // 是否是第一次通话
      allsocket: this.canVoipData// 所有的socket信息
    }
  },
  created () {
    this.$nextTick(() => {
      this.canVoipDataAll = this.canVoipData
      this.allsocket = this.canVoipData
    })
    this.currentVoipDataOld = this.currentVoipData
    if (this.currentVoipDataOld.length > 0) {
      this.currentVoipDataOld.map(item => { // 将正在live的选中
        this.checkList.push(item.peerId)
      })
    }
    const isStart = window.localStorage.getItem('mvIsStartVoip')
    if (isStart == 'false') {
      this.isStart = false
    }
  },
  computed: {},
  watch: {
    selectDevice (val) {
      this.selectDeviceVal = val
    },
    canVoipData (val) {
      this.canVoipData = val
    },
    currentVoipData (val) {
      this.currentVoipDataOld = val
    },
    allSocketData (val) { // 所有的websocket信息
      this.allsocket = val
    }
  },
  methods: {
    startCall () { // 开始通话
      if (this.checkList.length > 0) {
        this.isStart = false
        window.localStorage.setItem('mvIsStartVoip', 'false')
        this.sendMsg()
        Bus.$emit('voipWitchIcon', 1)
        multiviewAnalyze('multiviewVoipStart', this.$route.path)
      } else {
        this.$message({
          type: 'warning',
          message: this.$t('lang.selectObjCall')
        })
        return false
      }
    },
    editCall () { // 继续通话
      this.sendMsg()
      multiviewAnalyze('multiviewVoipReStart', this.$route.path)
    },
    sendMsg () { // 保存
      let selectArr = []
      this.checkList.map(item => {
        const peerId = item
        for (const item in this.allsocket) {
          if (item == peerId) {
            selectArr.push(this.allsocket[item])
          }
        }
      })
      Bus.$emit('voipCheckList', selectArr)//
      Bus.$emit('selectDeviceEmit', false)// 关闭弹窗
    },
    resetData () {
      this.checkList = []// 初始化数据
    },
    cancel () { // 取消
      Bus.$emit('selectDeviceEmit', false)
      this.resetData()
    }
  }

}
</script>
<style lang="less" scoped>
.add-source-dialog {
  z-index: 19999 !important;

  .content {
    text-align: left;
    font-size: 16px;

    .item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        display: block;
        margin-bottom: 5px;
      }
    }
    ::v-deep .el-checkbox__input {
      float: left;
    }
    ::v-deep .el-input .el-input__inner {
      border: 1px solid #444;
      background: #444;
      height: 44px;
      line-height: 44px;

      &::-webkit-input-placeholder {
        color: #666;
      }
    }

    ::v-deep .select-box {
      width: 100%;

      .el-input__inner {
        border: 1px solid #444;
        background: #444;
        height: 44px;
        line-height: 44px;
      }
    }

    ::v-deep .el-select {
      width: 100%;

      .el-select__input {
        color: #fff;
      }
    }

    ::v-deep .selectRlist {
      width: 100%;
      height: 300px;
      overflow-y: auto;
    }
  }

  ::v-deep .el-checkbox {
    display: block;
    width: 100%;
    line-height: 0.3rem;
    overflow: hidden;
  }
}

::v-deep .el-dialog__wrapper {
  z-index: 2020;

  .el-dialog--center {
    z-index: 2021;
  }
}

.ecllips {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
